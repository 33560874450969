import "./App.css";
import { Idle } from "./pages/Idle";
import { Home } from "./pages/Home";
import { Start } from "./pages/Start";
import { WaitingForPOS } from "./pages/WaitingForPOS";
import { Receipt } from "./pages/Receipt";
import { End } from "./pages/End";
import { ReadyToStop } from "./pages/ReadyToStop";
import { EndStop } from "./pages/EndStop";
import { Feedback } from "./pages/Feedback";
import { useDispatch, useSelector } from "react-redux";
import { activeViewSelector } from "./store";
import { SelectConnectorID } from "./pages/SelectConnectorID";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { PaymentError } from "./pages/PaymentError";
import { RecognitionError } from "./pages/RecognitionError";
import { IdleTimerProvider } from "react-idle-timer";
import { navigate } from "./reducers/views";
import { ReactElement, useState } from "react";
import { TimeoutModal } from "./components/TimeoutModal";


type idleTimerProps = {
  component: ReactElement;
  timeout: number;
}

const IdleTimerContainer = ({component, timeout}: idleTimerProps) => {
  const dispatch = useDispatch();
  const [showTimeout, setShowTimeout] = useState(false);
  const onIdle = () => {
    setShowTimeout(false)
    dispatch(navigate("Idle"));
  };
  const onPrompt = () => {
    setShowTimeout(true)
  }

  const onActive = () => {};
  return (
    <IdleTimerProvider timeout={timeout} onIdle={onIdle} onActive={onActive} onPrompt={onPrompt} promptTimeout={10 * 1000}>
      {component}
      <TimeoutModal show={showTimeout} handleClose={() => setShowTimeout(false)} />
    </IdleTimerProvider>
  )
}

const App = () => {
  const activeView = useSelector(activeViewSelector);

  const timeouts = {
    mid: 60 * 1000,
    long : 120 * 1000,
    short: 30 * 1000,
  }
  switch (activeView) {
    case "Idle":
      return <Idle />;
    case "Home":
      return <IdleTimerContainer component={<Home />} timeout={timeouts.mid}/>;
    case "Start":
      return <IdleTimerContainer component={<Start />} timeout={timeouts.mid}/>;
    case "SelectConnectorID":
      return <IdleTimerContainer component={<SelectConnectorID />} timeout={timeouts.mid}/>;
    case "WaitingForPOS":
      return <IdleTimerContainer component={<WaitingForPOS />} timeout={timeouts.long}/>;
    case "PaymentError":
      return <IdleTimerContainer component={<PaymentError />} timeout={timeouts.mid}/>
    case "RecognitionError":
      return <IdleTimerContainer component={<RecognitionError />} timeout={timeouts.mid}/>
    case "End":
      return <IdleTimerContainer component={<End />} timeout={timeouts.short}/>
    case "Receipt":
      return <IdleTimerContainer component={<Receipt />} timeout={timeouts.mid}/>
    case "TermsAndConditions":
      return <IdleTimerContainer component={<TermsAndConditions />} timeout={timeouts.mid}/>
    case "ReadyToStop":
      return <IdleTimerContainer component={<ReadyToStop />} timeout={timeouts.long}/>
    case "EndStop":
      return <IdleTimerContainer component={<EndStop />} timeout={timeouts.short}/>
    case "Feedback":
      return <IdleTimerContainer component={<Feedback />} timeout={timeouts.mid}/>
    default:
      console.log(`No such view: ${activeView}`);
      break;
  }
  return null;
}

export default App;