import backIcon from "../assets/recharge/footer-back-r.png";
import forward from "../assets/recharge/footer-forward-r.png";
import cancelIcon from "../assets/recharge/footer-cancel-r.png";
import { useDispatch } from "react-redux";
import { goBack, navigate, resetViews, View } from "../reducers/views";
import { ChooseLanguage } from "./ChooseLanguage"

type Props = {
  next?: View;
  cancel?: boolean;
  back?: boolean;
  loading?: boolean;
  onBackPressed?(): void;
  onNextPressed?(): void;
}

export const Footer = ({
  cancel,
  back,
  next,
  loading,
  onBackPressed,
  onNextPressed,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="bg-primary footer">

      <div className="d-flex justify-content-around align-items-center mt-5">
        {(back || onBackPressed) && (
          <button
            className="bg-transparent btn-outline-none svg-secondary"
            onClick={onBackPressed ? onBackPressed : () => dispatch(goBack())}
          >
            <img src={backIcon} alt="Back" width={100} />
          </button>
        )}
        {cancel && (
          <button
            className="bg-transparent btn-outline-none svg-secondary"
            onClick={() => dispatch(resetViews())}
          >
            <img src={cancelIcon} alt="Cancel" width={100} />
          </button>
        )}
        {(next || onNextPressed) && (
          <button
            className="bg-transparent btn-outline-none svg-secondary"
            onClick={next ? () => dispatch(navigate(next)) : onNextPressed}
          >
            <img src={forward} alt="Ok" width={100} />
          </button>
        )}
        {loading && (
          <div className="spinner-grow text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
        <div className="d-flex justify-content-around align-items-center mb-2 mt-5" >
          <ChooseLanguage />
      </div>
    </div>
  );
}