import "./styles.scss";
import logo from "../assets/recharge/recharge-logo.svg";
import { ReactElement, useEffect, useState } from "react";
import { StartButton } from "../components/StartButton";
import { StopButton } from "../components/StopButton";
import { Footer } from "../components/Footer";
import { setDebugKioskToken, debugKioskToken } from "../clients/TotemApiClient";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { navigate } from "../reducers/views";

export const Home = () : ReactElement => {
  const [, shouldUpdate] = useState(0);
  const { i18n } = useTranslation();
  
  useEffect(() => {
    const checkLocale = () : string => {
      if (debugKioskToken.includes("norway")){
        return "no"
      } else if (debugKioskToken.includes("finland")){
        return "fi"
      }else if (debugKioskToken.includes("sweden")){
        return "se"
      }
      return "en"
    }
    i18n.changeLanguage(checkLocale())

    // Reset session in case user is navigating back
    // eslint-disable-next-line
  }, [i18n, debugKioskToken]); //disable eslint for debugKioskToken (to be removed)
  const dispatch = useDispatch()
  return (
    <div className="home-bg view-height d-flex flex-column justify-content-center align-items-center ">
      <div className="container-sm mb-5" style={{ width: 750 }}>
        <ChangeKioskToken onClick={() => shouldUpdate(prev => prev+1)} />
        <StartButton />
        <StopButton />
      </div>
      <img
          src={logo}
          width={230}
          alt="Recharge logo"
          className="recharge-logo"
          onClick={() => dispatch(navigate("TermsAndConditions"))}
        />
      <Footer back/>
    </div>
  );
}

// Remove these from production
interface ChangeKioskTokenProps {
  onClick: () => void
}

const ChangeKioskToken = (props: ChangeKioskTokenProps) => {
  const options = [
    {token: "farol-hq", text: "HQ"},
    {token: "recharge-pilot-finland", text: "FI"},
    {token: "recharge-pilot-norway", text: "NO"}
  ]
  return <div style={{ position: "absolute", top: 20, left: 10 }}>
    <p className="text-secondary">current enviroment (kiosk-token): {debugKioskToken}</p>
    {options.map(option => 
      <button
        style={{ width: 100, height: 50, fontSize: 20, backgroundColor: "#FF7500", margin: 5 }}
        key={option.text}
        onClick={() => {
          setDebugKioskToken(option.token)
          props.onClick()
        }}
      >
        {option.text}
      </button>
    )}
  </div>
}

// ----------------------------------------

