import { Modal} from "react-bootstrap";

type Props = {
  show?: boolean;
  handleClose(): void;
};

export const ProcessingCancellationModal = ({ show, handleClose }: Props) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <h1>Processing cancellation</h1>
        <br />
        <h2>Make sure card is removed</h2>
      </Modal.Body>
    </Modal>
  );
};
