import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useIdleTimerContext } from "react-idle-timer";
import expiredIcon from "../assets/icons/expired-icon.svg";
import { useTranslation } from "react-i18next";

type Props = {
  show?: boolean;
  handleClose(): void;
};

export const TimeoutModal = ({ show, handleClose }: Props) => {
  const idleTimer = useIdleTimerContext();
  const { t } = useTranslation();

  const [remaining, setRemaining] = useState(10);

  useEffect(() => {
    setInterval(() => {
      setRemaining(Math.trunc(idleTimer.getRemainingTime() / 1000));
    }, 1000);
  }, [idleTimer]);

  const resetAndClose = () => {
    handleClose();
    idleTimer.reset();
  };

  return (
    <Modal show={show} onHide={resetAndClose} size="lg" centered>
      <Modal.Header
        closeButton
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          opacity: 1,
          zIndex: 1055,
        }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <img
            style={{ width: 200, height: 200 }}
            className="svg-secondary"
            alt="expired icon"
            src={expiredIcon}
            />
        <h1>{t("stillThere")}</ h1>
        <h1>{t("expire")}</h1>
        <h1 className="text-huge text-primary">{remaining}</h1>
      </Modal.Body>
      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          variant="secondary round"
          className="mx-4"
          onClick={resetAndClose}
          style={{ width: 250, height: 100, fontSize: 35 }}
        >
          {t("continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
