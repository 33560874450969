import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Connector } from "../models/connector";

interface ChargingSessionState { connector: Connector, payment?: number, id?: string, selectedConnectors: Connector[] }

const initialState: ChargingSessionState = { connector: {}, selectedConnectors: [] }

const chargingSessionSlice = createSlice({
  name: "chargingSession",
  initialState,
  reducers: {
    setEvseUid: (state, action: PayloadAction<string | undefined>) => {
      state.connector.evseUid = action.payload
    },
    setPayment: (state, action: PayloadAction<number>) => {
      state.payment = action.payload
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    resetChargingSession: () => initialState,
    setSelectedConnectors: (state, action: PayloadAction<Connector[]>) => {
      state.selectedConnectors = action.payload
    }
  }
})

export const { 
  setPayment, setSessionId, resetChargingSession, setSelectedConnectors, setEvseUid
} = chargingSessionSlice.actions

export default chargingSessionSlice.reducer