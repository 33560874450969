export interface Session {
  id: string;
  status: SessionStatus;
}

export enum SessionStatus {
  REQUESTED = 'REQUESTED',
  WAITING_FOR_RESERVATION = 'WAITING_FOR_RESERVATION',
  RESERVATION_APPROVED = 'RESERVATION_APPROVED',
  WAITING_TO_START = 'WAITING_TO_START',
  STARTED = 'STARTED',
  WAITING_TO_CANCEL = 'WAITING_TO_CANCEL',
  CANCELLED = 'CANCELLED',
  START_REQUEST_REJECTED = 'START_REQUEST_REJECTED',
  FAILED_TO_REQUEST_RESERVATION = 'FAILED_TO_REQUEST_RESERVATION',
  FAILED_TO_APPROVE_RESERVATION = 'FAILED_TO_APPROVE_RESERVATION',
  FAILED_TO_REQUEST_START = 'FAILED_TO_REQUEST_START',
}