import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Footer } from "../components/Footer";
import { navigate } from "../reducers/views";

export const Receipt = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch()

  return (
    <>
      <div className="d-flex flex-column view-height justify-content-center align-items-center bg-white">
          
            <div className="d-flex flex-fill flex-column bg-white justify-content-center px-5 mt-5">
              <h1 className="text-huge text-primary text-center" style={{fontSize: 100}}>
                {t("receiptByEmail")}
              </h1>
              <div className="d-flex justify-content-center py-5 btn-group-vertical mx-5">
                <button
                  type="button"
                  className="btn btn-secondary fw-bold yes-receipt-button"
                  onClick={() => dispatch(navigate("TermsAndConditions"))}
                >
                  {t("yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary fw-bold no-receipt-button"
                  onClick={() => dispatch(navigate("Feedback")) }
                >
                  {t("no")}
                </button>
              </div>
            </div>
        <Footer />
      </div>
    </>
  );
}

