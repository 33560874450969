import { Modal, Button } from "react-bootstrap";

type Props = {
  show?: boolean;
  handleClose(): void;
};

export const TermsAndConditionsModal = ({ show, handleClose }: Props) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton style={{position: "sticky", top: 0, backgroundColor: "white", opacity: 1, zIndex: 1055}}>
        <Modal.Title>Terms And Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5> Recharge AS, Recharge Sweden AB, Recharge Finland OY</h5>
        <br />
        <h4>1 Introduction</h4>
        <p>
          Recharge AS, Recharge Sweden AB and Recharge Finland OY is currently
          operating in Norway, Sweden and Finland (hereinafter “Recharge”) and
          offers charging solutions, and services related to charging of
          electrical vehicles, as well as payment solutions and charging
          services (hereinafter “The Service”).
        </p>
        <p>
          Third party-service provider (hereinafter “Service Provider”) is a
          legal entity that will provide services to the Customers when using
          RFID, mobile application or other service.
        </p>
        <p>
          When using Recharge Service or equipment, the customer (hereinafter
          “The Customer”) will accept the content of these terms (“hereinafter
          “The Terms”).
        </p>
        <p>
          The Terms and provisions apply for the use of the Service on chargers
          connected to the networks of Recharge, or at our partner sites. Please
          read the Terms carefully, and if you do not consent to these Terms you
          should not proceed further with our Service.
        </p>
        <h4>2 Use of Services</h4>
        <p>
          To receive and fully utilize the Services, the Customer shall
          authenticate themselves either to our Services using text/SMS message,
          RFID card, mobile application or Recharge web payment solution
        </p>
        <p>
          Recharge do not issue any RFID cards. To use RFID or mobile
          application the Customer needs to accept both the Privacy Policy of
          Recharge as well as Service Providers terms before accessing the
          Service. The Customer is responsible for knowing the valid terms and
          conditions at the Service Provider.
        </p>
        <p>
          Our drop in Services consists of either SMS or web payment solution.
          The Services provided by Recharge may vary depending on which country
          you are located in.
        </p>
        <h4>3 Price and payment</h4>
        <p>
          By accepting the Terms and conditions upon using the Service, the
          Customer accepts that Recharge will debit the charging fee, including
          applicable taxes and service charges. Our prices are available at our
          homepage:
        </p>
        <p>http://www.rechargeinfra.com/map/prices-norway/</p>
        <p>http://www.rechargeinfra.com/map/prices-sweden/</p>
        <p>http://www.rechargeinfra.com/map/prices-finland/</p>
        <p>
          The applicable price of the charging service is available at the
          Recharge web payment solutions, and/or at the Service Providers
          applications. The service price may also be Customer-specific, in
          which case it might differ from the basic price. The prices may vary.
        </p>
        <p>
          The final charging fee, which is based on the time of charging and/or
          the amount charged and the predetermined price informed to the
          Customer before charging, will be calculated once the charging is
          completed and debited from the Customer.
        </p>
        <p>
          Charging is completed either by the system according to the
          Customer&#8217;s instructions when charging is begun or extended, or
          by the Customer at any time.
        </p>
        <p>
          Recharge Services can only be used with chargers at charging stations
          connected to the networks of Recharge or its partners. The chargers
          are made visible on Recharge&#8217;s website and fitted with Recharge
          markings.
        </p>
        <p>
          To access Recharge Services, the following alternatives are available:
        </p>
        <h6>SMS customer</h6>
        <p>
          Using the Service via SMS requires the Customer to provide Recharge
          with personal information such as mobile phone number and location.
        </p>
        <p>
          Charging paid for via text message is debited by the Customer&#8217;s
          mobile operator on the Customer&#8217;s mobile phone invoice. In
          addition, the mobile operator may charge for any text message costs
          (data communication charges).
        </p>
        <h6>Drop in customer</h6>
        <p>
          When charging is initiated by drop in card payment through Recharges
          web payment solutions, an amount of €50 will be reserved on the
          payment card for Finland, SEK 500 for Sweden and NOK 500 for Norway.
          When the charging is finished, we release the reservation and charge
          the payment card for the session.
        </p>
        <h6>RFID / Mobil application</h6>
        <p>
          RFID / Mobil application Recharge do not offer any subscriptions or
          other recuring customer agreements. For charging via RFID or mobile
          application or other memberships the Customer need sign up with a
          Service Provider.
        </p>
        <p>
          A Service Provider may apply different registrations requirements and
          processes which may also change over time.
        </p>
        <h4>4 Cancellation of Service</h4>
        <p>
          Cancellation of services is not possible for Recharge drop in/SMS
          customers when accepting these Terms and conditions.
        </p>
        <p>
          To find out more about cancellation rights for services provided by a
          Service Provider, the Customer needs to contact its Service Provider
          directly.
        </p>
        <h4>5 Customers obligations</h4>
        <p>
          It is the Customer&#8217;s responsibility to ensure that charging
          begins and is completed correctly. To ensure that the charging has
          begun, the Customer shall refer to the information visible on the
          charger. The Customer is responsible for:
        </p>
        <ul>
          <li>
            The Customer shall accept Recharge terms and conditions for payment
            before using the Service
          </li>
          <li>
            The Customer shall accept terms and condition from the Service
            Provider before using charging via RFID or mobile application
          </li>
          <li>
            The Customer is responsible for any unauthorized use of the Service
            under their control
          </li>
          <li>
            The Customer is responsible for having a valid phone subscription
            that will allow the Service
          </li>
          <li>
            Ensuring that charging is properly initiated, and that procedures
            for charging is correctly followed either this is for SMS, RFID,
            Mobile application, or web payment solutions as described at our
            application or station. Recharge is not responsible for loss, damage
            or other legal or economic consequences if the Customer does not
            follow the instruction correctly
          </li>
          <li>
            Ensure that the registered payment card is valid, has sufficient
            balance and is not blocked. Recharge has the right to seek payment
            by separate invoice for Customer use if debiting is not possible
          </li>
        </ul>
        <h4>6 Liability for Recharge</h4>
        <p>
          Recharge is not responsible for legal or financial, direct or indirect
          consequences for the Customer if there are damages on vehicle/
          equipment, loss or unavailability of the Service.{" "}
          <strong>This includes but not limited to: </strong>
        </p>
        <ul>
          <li>The cell phone network or related functions are not working</li>
          <li>
            The cell phone telephone operator has changed its telephone service
            so that payment via the Recharge service is no longer possible
          </li>
          <li>
            The Service has been suspended for reasons which prove to be
            incorrect, but Recharge, at the time of suspension, reason to
            believe that there were grounds for suspension
          </li>
          <li>
            The Customer’s RFID card or the Customer&#8217;s mobile phone is
            lost or stolen and misused by another person
          </li>
          <li>
            Recharge is not responsible for the Customer parking their vehicle
            in accordance with the law and regulations laid down by a partner
            company or municipality and not responsible for fines for such
            violations.
          </li>
          <li>
            Recharge is not responsible for damage or loss caused by a legal
            provision, official action, war, epidemic, sabotage, failure or
            delay in delivery, telephone connections or other traffic and
            communications connections and transportation, strike, boycott, or
            other similar circumstances beyond the control of Recharge. The
            provisions regarding strike, boycott and blockade also applies if
            Recharge is the subject of the said actions.
          </li>
          <li>
            Any damage or loss that occurs in other circumstances will not be
            reimbursed by Recharge is not responsible in relation to the
            Customer for indirect damage, such as loss of income or damage to
            the relationship between the
          </li>
        </ul>
        <p>
          Customer and third parties, except if the loss is caused by
          negligence, gross negligence or willfulness on the part of Recharge ,
          and never in excess of what the Customer is entitled to under
          mandatory legislation.
        </p>
        <h4>7 Privacy and cookies</h4>
        <p>
          Any personal information that is provided to us when using our
          Services will be used in accordance with{" "}
          <a href="https://rechargeinfra.com/fi/privacy-policy/">
            Recharge Privacy Policy
          </a>{" "}
          By using our application, you agree to{" "}
          <a href="https://rechargeinfra.com/fi/privacy-policy/">
            Recharge Privacy Policy
          </a>{" "}
          and Recharge Terms of Service.
        </p>
        <p>
          The Privacy policy can be read more about in detail by visiting it{" "}
          <a href="https://rechargeinfra.com/fi/privacy-policy/">here..</a>
        </p>
        <h4>8 Complaint, Consumer Purchase Act, and disputes</h4>
        <p>
          All purchase agreements made between you and Recharge are governed by
          the law stated in the country where the Service were rendered.
        </p>
        <p>
          If a Customer wishes to submit a complaint for payment or other faults
          regarding third party service provider for APP/RFID, the Customer
          shall notify the Service Provider directly and not Recharge.
        </p>
        <p>
          Any complaints against Recharge must be sent to within 60 days of the
          date of the purchase transaction in question.
        </p>
        <p>
          The Customer may deliver complaint either on a free-format form
          electronically to
        </p>
        <p>
          <strong>Norway</strong>:{" "}
          <a href="mailto:asiakaspalvelu@rechargeinfra.com">
            asiakaspalvelu@rechargeinfra.com
          </a>
        </p>
        <p>
          <strong>Sweden</strong>:{" "}
          <a href="mailto:kundservice@rechargeinfra.com">
            kundservice@rechargeinfra.com
          </a>
        </p>
        <p>
          <strong>Suomi</strong>:{" "}
          <a href="mailto:asiakaspalvelu@rechargeinfra.com">
            asiakaspalvelu@rechargeinfra.com
          </a>
        </p>
        <p>
          <strong>English</strong>:{" "}
          <a href="mailto:support@rechargeinfra.com">
            support@rechargeinfra.com
          </a>
        </p>
        <h6>or by telephone to our customer service.</h6>
        <p>
          Customer service in Finland: tel. +358 20 33 4455 (0,084€/min)
          <br />
          Customer service in Sweden: tel. +46 020 &#8211; 46 00 20
          <br />
          Customer service in Norway: tel. +47 69 14 14 10
        </p>
        <p>
          The complaint shall clearly indicate the type of error. If this is not
          done correctly, the Customer will lose their right for the error
          investigation and correction, apart from exceptions due to mandatory
          legislation. When a Customer submits a complaint, Recharge shall carry
          out a technical investigation.
        </p>
        <p>
          Complaints regarding incorrect charging costs are processed and
          determined by Recharge. If and/or when a complaint is accepted,
          Recharge shall compensate the Customer for the amount without delay.
          If the complaint is rejected, Recharge shall inform the Customer of
          the result of the investigation of the complaint and the reason for
          the rejection.
        </p>
        <p>
          The Customer has the right to take any disputes arising between the
          Customer and Recharge to be settled by Consumer Disputes board or
          District court, or to use any other rights granted by the law
          submitted to the country where the Service is provided.
        </p>
        <h4>9 Change of terms</h4>
        <p>
          Both parties are aware that the electric vehicle charging sector is
          rapidly developing. Recharge therefore reserves the right to modify
          these terms and conditions in order to adapt the operation of the
          Recharge service or the terms and conditions to new or modified
          technologies, devices, standards, legislation, policies, or to
          appropriate technical, information security, administrative, business,
          operations&#8217;, or other relevant procedures.
        </p>
        <p>
          The Customer shall be informed of such changes on the website
          www.rechargeinfra.com.
        </p>
        <p>
          Do not assume that the terms and are applicable on a previous occasion
          once you have rendered a Service from us will still apply at the next
          event. We therefore recommend that you always read these Terms before
          a new order to make sure you accept them.
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button variant="secondary" onClick={handleClose} style={{width: 150, height: 80, fontSize: 30}}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
