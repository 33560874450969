import { useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import { TotemApiClient } from "../clients/TotemApiClient";
import { Recognition, RecognitionStatus } from "../models/recognition";
import { pause } from "../utils";
import { useTranslation } from "react-i18next";
import { goBack, navigate } from "../reducers/views";
import { useDispatch } from "react-redux";
import arrowDown from "../assets/icons/arrow-down.svg"

export const ReadyToStop = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const client = new TotemApiClient();
  const [storedRecognitionId, setStoredRecognitionId] = useState<string|undefined>();
  const isDevEnv = process.env.NODE_ENV === "development";

  const simulatePOS = (recognitionId: string) => {
    client.simulateWorldLineRecognitionCallback({ recognitionId })
  }

  const pollRecognition = async (recognitionId: string): Promise<Recognition> => {
    await pause(1000);
    return client.getRecognition(recognitionId)
    .then(recognition => {
      if (recognition.status !== RecognitionStatus.STARTED) {
        return recognition
      } else {
        return pollRecognition(recognitionId)
      }
    })
  }

  useEffect(() => {
    client.startRecognition()
      .then(({ id }) => {
        isDevEnv && setStoredRecognitionId(id);
        return pollRecognition(id)
      })
      .then(({ status, session }) => {
        // TODO: add accurate success / error handling for cancellation (may need a check for cancellation status type in the DTO)
        if (status === RecognitionStatus.FAILURE) {
          console.log("status === RecognitionStatus.FAILURE")
          dispatch(navigate("RecognitionError"))
        } else if (status === RecognitionStatus.CANCELLED) {
          console.log("status === RecognitionStatus.CANCELLED")
          dispatch(goBack())
        }
        session ? client.stopSession(session.id) : console.log("This card doesn't have ongoing sessions")
        if (status === RecognitionStatus.CONCLUDED) {
          dispatch(navigate("EndStop"))
        }
      })
      .catch(e => console.log("something wrong happened", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = async (recognitionId: string | undefined) => {
    if (recognitionId) {
      client.cancelRecognition(recognitionId);
    }
    dispatch(goBack())
  };

  return (
    <>
      <div className="d-flex flex-column view-height bg-white">
        {
          // Add a POS simulation button when in development
          storedRecognitionId && isDevEnv && (
            <button className="btn btn-primary" onClick={() => simulatePOS(storedRecognitionId)}>Simulate POS</button>
          )
        }
        <div className="container-sm flex-fill bg-white d-flex flex-column justify-content-end" style={{ maxWidth: 770, maxHeight: 810 }}>
          <h1 className="text-huge" style={{ marginBottom: 200 }}>{t("tapYourCard")} </h1>
        </div>
        <div className="flex-fill bg-secondary d-flex flex-column justify-content-center align-items-center" style={{maxHeight: 810}}>
          <div className="container-sm flex-fill bg-secondary d-flex flex-column justify-content-start" style={{ maxWidth: 770 }}>
            <h1 className="text-white" style={{ marginTop: 250 }}>
              {t("readyToStopInfo")}                
            </h1>
            <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              className="arrow bounce"
              src={arrowDown}
              alt="arrow down"
            />
            </div>

          </div>
        </div>
      </div>
      <Footer onBackPressed={() => handleCancel(storedRecognitionId)} />
    </>
  );
}