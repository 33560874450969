import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Connector,
  ConnectorStatus,
  ConnectorGroups,
  ConnectorType,
  connectorTypeImage,
  connectorTypeName,
  connectorTypePrice,
} from "../models/connector";
import { navigate } from "../reducers/views";
import { setEvseUid, setSelectedConnectors } from "../reducers/chargingSession";
import { useDispatch } from "react-redux";
import { TotemApiClient, debugKioskToken } from "../clients/TotemApiClient";

export const StartButton = () => {
  const [isLoading, setLoading] = useState(true);
  const [connectorComponents, setConnectorComponents] = useState([] as ReactNode[]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = new TotemApiClient();

  const groupByConnectorType = (acc: ConnectorGroups, connector: Connector) => {
    const connType = connector.connectorType;
    const prevConnectors = acc.get(connType) ?? [];
    acc.set(connType, prevConnectors.concat(connector));
    return acc;
  };
  useEffect(() => {
    dispatch(setEvseUid(undefined));
    // fetch location connectors from the server
    client
      .getConnectors()
      .then((receivedConnectors: Connector[]) => {
        const groups = receivedConnectors.reduce(
          groupByConnectorType,
          new Map()
        );
        const nodes = [] as ReactNode[];
        let i = 0;
        groups.forEach((connectors, connectorType) => {
          nodes.push(
            <ConnectorGroupButton
              key={connectorType}
              connectors={connectors}
              connectorType={connectorType as ConnectorType}
              onSelect={() => onSelect(connectors)}
              hasBorderRight={i < connectors.length - 1}
              hasBorderLeft={i !== 0}
            />
          )
          i++;

        });
        setConnectorComponents(nodes)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debugKioskToken]);

  const onSelect = (connectors: Connector[]) => {
    const availConnectors = connectors.filter(
      (con) => con.status === ConnectorStatus.AVAILABLE
    );
    dispatch(setSelectedConnectors(availConnectors));
    dispatch(navigate("Start"));
  };

  return (
    <div className="mb-5 row start-div round-top">
      <div
        className="bg-primary col round-top"
        style={{ minHeight: 200, width: "100%" }}
      >
        <h1 className="text-home-huge text-secondary pt-4 mb-3 ms-4 text-uppercase">
          {t("start")}
        </h1>
        <h4 className="text-white ms-4">{t("selectConnectorToStart")}</h4>
      </div>
      <div
        className="btn-group bg-connectors"
        role="group"
        style={{ minHeight: 250, width: "100%" }}
      >
        {!isLoading && connectorComponents}
      </div>
    </div>
  );
};

type ButtonProps = {
  connectorType: ConnectorType;
  connectors: Connector[];
  onSelect: () => void;
  hasBorderRight: boolean
  hasBorderLeft: boolean
};

const ConnectorGroupButton = (props: ButtonProps) => {
  if (props.connectors.length === 0) return null;
  const isEnabled = props.connectors.some(
    (c) => c.status === ConnectorStatus.AVAILABLE
  );

  const borderStyle = "1px solid black";

  return (
    <div className="" style={{ width: "100%" }}>
      {isEnabled ? (
        <div
          onClick={props.onSelect}
          className="col "
          style={{ minHeight: 250, height: "100%", 
          borderLeft: props.hasBorderLeft ? borderStyle : "unset",
          borderRight: props.hasBorderRight ? borderStyle : "unset"
      
        }}
        >
          {connectorBox(props.connectorType)}
        </div>
      ) : (
        <div
          className="col"
          style={{ minHeight: 250, opacity: 0.6, height: "100%" }}
        >
          {connectorBox(props.connectorType)}
        </div>
      )}
    </div>
  );
};

const connectorBox = (connectorType: ConnectorType): ReactNode => (
  <>
    <div className="text-center">
      <h1 className="text-white pt-3 p-0">
        {connectorTypeName(connectorType)}
      </h1>
      <h4 className="text-white">{connectorTypePrice(connectorType)}</h4>
    </div>
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: 150, borderTop: "1px solid black" }}
    >
      <img
        src={connectorTypeImage(connectorType)}
        className="white-icon "
        alt={connectorType}
        height={"80%"}
      />
    </div>
  </>
);
