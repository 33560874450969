import "./styles.scss";
import logo from "../assets/recharge/recharge-logo.svg";
import { navigate } from "../reducers/views";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { resetChargingSession } from "../reducers/chargingSession";
//import { TotemApiClient } from "../clients/TotemApiClient";
import { useTranslation } from "react-i18next";
import { setDebugKioskToken, debugKioskToken } from "../clients/TotemApiClient";

export const Idle = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [, shouldUpdate] = useState(0);

  useEffect(() => {
    //const client = new TotemApiClient();

    const checkLocale = () : string => {
      
      /*if (client.kioskToken.includes("norway")){
        return "no"
      } else if (client.kioskToken.includes("finland")){
        return "fi"
      }else if (client.kioskToken.includes("sweden")){
        return "se"
      }
      return "en"
    }*/
    if (debugKioskToken.includes("norway")){
      return "no"
    } else if (debugKioskToken.includes("finland")){
      return "fi"
    }else if (debugKioskToken.includes("sweden")){
      return "se"
    }
    return "en"
  }
    i18n.changeLanguage(checkLocale())

    // Reset session in case user is navigating back
    dispatch(resetChargingSession());
    // eslint-disable-next-line
  }, [dispatch, i18n, debugKioskToken]); //disable eslint for debugKioskToken (to be removed)
  
  const prices = t("prices", {returnObjects: true}) as Array<any>;
  const handleClick = (event: any) => {
      if(event.target.nodeName === "BUTTON"){
        shouldUpdate(prev => prev+1)
      } else  {
        dispatch(navigate("Home"))
      }
  }
  return (
    <div
      className="bg-white vh-100 d-flex flex-column align-items-center"
      onClick={handleClick}

    >
      <ChangeKioskToken onClick={() => handleClick} />
      <div
        className="flex-fill bg-secondary d-flex flex-column justify-content-end vw-100"
        style={{
          maxWidth: "100%",
          maxHeight: 700,
          paddingBottom: 100,
          paddingLeft: 100,
        }}
      >
        <h1 className="text-huge">{t("touchToStart")}</h1>
        <h2 className="heading-2 text-white">
        {t("payHere")}
        </h2>
      </div>
      <div className="container-sm" style={{ maxWidth: 800, maxHeight: 1980, paddingTop: 100 }}>
        <div>
          {prices.map((p) => (
            <div className="row">
              <h2 className="text-primary fw-bold pt-4" style={{fontSize: 48}}>
                {p.chargingType}
              </h2>
              <h2 className="heading-2 text-primary pt-2">{p.info}</h2>
              <h2 className="heading-2 text-primary pt-2 pb-4">{p.price}</h2>
              <div className="border border-1 border-secondary"></div>
            </div>
          ))}
        </div>
      </div>
      <img
        src={logo}
        width={230}
        alt="Recharge logo"
        className=""
        style={{ position: "fixed", bottom: 100 }}
      />
    </div>
  );
};

// Remove these from production
interface ChangeKioskTokenProps {
  onClick: () => void
}

const ChangeKioskToken = (props: ChangeKioskTokenProps) => {
  const options = [
    {token: "farol-hq", text: "HQ"},
    {token: "recharge-pilot-finland", text: "FI"},
    {token: "recharge-pilot-norway", text: "NO"}
  ]
  return <div style={{ position: "absolute", top: 20, left: 10 }}>
    <p className="text-white">current enviroment (kiosk-token): {debugKioskToken}</p>
    {options.map(option => 
      <button
        className="bg-white"
        style={{ width: 100, height: 50, fontSize: 20, margin: 5 }}
        key={option.text}
        onClick={() => {
          setDebugKioskToken(option.token)
          props.onClick()
        }}
      >
        {option.text}
      </button>
    )}
  </div>
}
// ----------------------------------------