import { useTranslation } from "react-i18next";
import { Footer } from "../components/Footer";
import { useDispatch } from "react-redux";
import { resetViews } from "../reducers/views";
import logo from "../assets/recharge/recharge-logo.svg";

export const EndStop = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <div className="d-flex flex-column view-height flex-column justify-content-center align-items-center bg-white">
          <div className="container-sm" style={{ maxWidth: 770 }}>
                <h1 className="text-primary text-huge text-center pb-5">
                  {t("thankYou")}
                </h1>
                <h2 className="text-secondary mt-5 text-center heading-2">
                  {t("sessionWillStop")}
                </h2>
                <h2 className="text-secondary text-center heading-2">
                  {t("safeTravels")}
                </h2>
          </div>
        <img
          src={logo}
          width={230}
          alt="Recharge logo"
          className="recharge-logo"
          style={{  }}
        />
      </div>
      <Footer back={false} onNextPressed={() => dispatch(resetViews())} />
    </>
  );
}
