import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "../components/Footer";
import { useDispatch, useSelector  } from "react-redux";
import { connectorsSelector } from "../store";
import { setEvseUid, setPayment } from "../reducers/chargingSession";
import { navigate } from "../reducers/views";
import { TotemApiClient } from "../clients/TotemApiClient";
import { Cabinet, CabinetType, getImageForCabinet } from "../models/cabinet";

interface CabinetWithAvailability extends Cabinet {
  disabled: boolean;
}

export const SelectConnectorID = () => {
  const [availableCabinets, setAvailableCabinets] = useState<CabinetWithAvailability[]>([])
  const { t } = useTranslation();
  const connectors = useSelector(connectorsSelector);
  const dispatch = useDispatch();

  const initCabinets = useCallback(async () => {
    const client = new TotemApiClient();
    const cabinets = await client.getCabinets();
    setAvailableCabinets(cabinets
      .sort((a, b) => a.position - b.position)
      .map(cabinet => {
        const isDisabled = !cabinet.connectors.some(con =>
          connectors.find(connector => connector.connectorId === con.connectorId)
        );
        return { ...cabinet, disabled: isDisabled };
      })
    );
  }, [connectors])

  useEffect(() => {
    // Reset selection in case user is navigating back
    dispatch(setEvseUid(undefined))
    initCabinets();
  }, [dispatch, initCabinets]);

  const handleSelection = (cabinet: Cabinet) => {
    // Return first matching connector.
    // This does not support selection of a specific matching connector, in case there are several in one cabinet.
    const connector = connectors.find(con =>
      cabinet.connectors.find(c => c.connectorId === con.connectorId)
    )!
    dispatch(setEvseUid(connector.evseUid))
    dispatch(setPayment(0.2))
    dispatch(navigate("WaitingForPOS"))
  }
  
  return (
    <div className="d-flex bg-white flex-column view-height justify-content-center">
      <div className="container-sm" style={{ zIndex: 1, marginTop: 150 }}>
        <div className="mb-5 row start-div round-top" style={{ minHeight: 800 }}>
          <div className="bg-primary col round-top" 
            style={{ minHeight: 200, width: "100%" }}>
              <h1
                className="text-home-huge text-secondary pt-4 mb-3 ms-4 text-uppercase"
              >
                {t("select")}
              </h1>
              <h4 className="text-white ms-4">{t("chooseConnector")}</h4>
          </div>
          <div className="btn-group bg-connectors"
            role="group"
            style={{ minHeight: 550, maxHeight: "calc(100% - 200px)", width: "100%", paddingLeft: 0, paddingRight: 0 }}
          >
            {availableCabinets.map((cabinet, i) => (
              <CabinetCol
                key={cabinet.position}
                cabinetType={cabinet.cabinetType}
                position={cabinet.position}
                onClick={cabinet.disabled ? undefined : () => handleSelection(cabinet)}
                // 960 is the width of the elevated element
                width={960 / availableCabinets.length}
                hasBorderRight={i < availableCabinets.length - 1}
                hasBorderLeft={i !== 0}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer back  />
    </div>
  )
}

interface CabinetColProps {
  cabinetType: CabinetType
  position: number
  onClick?: () => void
  width: number
  hasBorderRight: boolean
  hasBorderLeft: boolean
}

const CabinetCol = (props: CabinetColProps) => {
  const borderStyle = "1px solid black";
  return (
    <div
      style={{
        boxSizing: "border-box",
        width: props.width,
        borderLeft: props.hasBorderLeft ? borderStyle : "unset",
        borderRight: props.hasBorderRight ? borderStyle : "unset"
      }}
      onClick={props.onClick}
      >
      <div
        style={{
          borderBottom: borderStyle, height: "40%", boxSizing: "border-box"
        }}
      >
        <h1
          className="text-home-huge text-white"
          style={{
            fontSize: 200, textAlign: "center",
            opacity: props.onClick ? 1 : .3
          }}
        >
          {props.position}
        </h1>
      </div>
      <div
        style={{
          display: "flex", justifyContent: "center", alignItems: "center",
          height: "60%", maxHeight: "60%"
        }}
      >
        <img
          src={getImageForCabinet(props.cabinetType)}
          alt="EV charging cabinet"
          style={{
            maxHeight: "80%", opacity: props.onClick ? 1 : .3,
          }}
        />
      </div>
    </div>
  )
}