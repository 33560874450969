import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { TotemApiClient } from "../clients/TotemApiClient";
import { Footer } from "../components/Footer";
import { Session, SessionStatus } from "../models/session";
import { pause, } from "../utils";
import { chargingSessionPaymentSelector, evseUidSelector } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { goBack, navigate } from "../reducers/views";
import { setSessionId } from "../reducers/chargingSession";
import arrowDown from "../assets/icons/arrow-down.svg"
import { ProcessingCancellationModal } from "../components/ProcessingCancellationModal";

export const WaitingForPOS = () => {
  const client = new TotemApiClient();
  const [storedSessionId, setStoredSessionId] = useState<string|undefined>();
  const stopPolling = useRef(false);
  const isDevEnv = process.env.NODE_ENV === "development";
  const { t } = useTranslation();

  const evseUid = useSelector(evseUidSelector)
  const chargingSessionPayment = useSelector(chargingSessionPaymentSelector)
  
  const dispatch = useDispatch()

  const [showPC, setShowPC] = useState(false);
  const handleShowPC = () => setShowPC(true);
  const handleClose = () => setShowPC(false);

  const simulatePOS = (sessionId: string) => {
    client.simulateWorldLineReferenceCallback({ sessionId })
  }

  const pollSession = async (sessionId: string): Promise<Session> => {
    if (stopPolling.current) {
      return Promise.reject()
    }
    await pause(1000)
    return client.getSession(sessionId)
    .then(session => {
      console.log("Polling session... sessionStatus: " + session.status)
      if (session.status !== SessionStatus.WAITING_FOR_RESERVATION &&
        session.status !== SessionStatus.WAITING_TO_CANCEL) {
        return session
      } else {
        if (session.status === SessionStatus.WAITING_TO_CANCEL) {
          handleShowPC()
        }
        return pollSession(sessionId)
      }
    })
  }

  useEffect(() => {
    client.startSession({
      evseUid: evseUid!,
      amountToReserve: chargingSessionPayment || NaN,
    }).then((session) => {
      isDevEnv && setStoredSessionId(session.id);
      return pollSession(session.id);
    }).then(({ id, status }) => Promise.all([id, status, client.getEmail(id)]))
      .then(([id, status, isEmailPresent]) => {
        console.log("sessionStatus: " + status)
        if (status === SessionStatus.FAILED_TO_APPROVE_RESERVATION ||
          status === SessionStatus.FAILED_TO_REQUEST_RESERVATION ||
          status === SessionStatus.FAILED_TO_REQUEST_START
          ) {
          dispatch(navigate("PaymentError"))
        }
        else if (status === SessionStatus.CANCELLED ||
          status === SessionStatus.START_REQUEST_REJECTED) {
          dispatch(goBack())
        }
        else if (isEmailPresent) {
          dispatch(navigate("Feedback"))
        }
        else {
          dispatch(setSessionId(id))
          dispatch(navigate("Receipt"))
        }})
      .catch(e => {
        console.log("Session polling stopped or something wrong happened", e)
        dispatch(navigate("PaymentError"))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = async (sessionId: string | undefined) => {
    if (sessionId) {
      client.cancelSession(sessionId)
      console.log("Cancelling initiated")
  }
}
  return (
    <>
      <div className="d-flex flex-column view-height bg-white">
        {
          // Add a POS simulation button when in development
          storedSessionId && isDevEnv && (
            <button className="btn btn-primary" onClick={() => simulatePOS(storedSessionId)}>Simulate POS</button>
          )
        }
        <div className="container-sm flex-fill bg-white d-flex flex-column justify-content-end" style={{ maxWidth: 770, maxHeight: 810 }}>
          <h1 className="text-primary text-huge" style={{ marginBottom: 100 }}>
            {t("followInstructions")}
          </h1>
        </div>
        <div className="flex-fill bg-secondary d-flex flex-column justify-content-start" style={{ maxHeight: 810}}>
          <div className="container-sm" style={{ maxWidth: 770 }}>
            <h1 className="text-white" style={{ marginTop: 250 }}>{t("willReserve")}</h1>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                className="arrow bounce"
                src={arrowDown}
                alt="arrow down"
              />
            </div>
          </div>
        </div>
        <div className="my-5">
        <ProcessingCancellationModal show={showPC} handleClose={handleClose} />
        </div>
        <Footer onBackPressed={() => handleCancel(storedSessionId)} />
      </div>
    </>
  );
}
