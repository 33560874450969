import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const views = [
  "Idle", "Home", "Start", "SelectConnector", "SelectConnectorID",
  "SelectAmount", "WaitingForPOS", "PaymentError", "RecognitionError", "Receipt", "End", "ReadyToStop", "EndStop",
  "Feedback", "TermsAndConditions"
] as const
export type View = typeof views[number]

interface ViewsState { active: View, history: View[] }

const initialState: ViewsState = { active: "Idle", history: [] }

const viewsSlice = createSlice({
  name: "views",
  initialState,
  reducers: {
    navigate: (state, action: PayloadAction<View>) => {
      state.history.push(state.active)
      state.active = action.payload
    },
    resetViews: () => initialState,
    goBack: (state) => {
      const prevView = state.history.pop()
      if (prevView !== undefined) {
        state.active = prevView
      }
    }
  },
})

export const { navigate, resetViews, goBack } = viewsSlice.actions

export default viewsSlice.reducer