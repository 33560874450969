import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Footer } from "../components/Footer";
import { resetViews } from "../reducers/views";
import logo from "../assets/recharge/recharge-logo.svg";

export const End = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  return (
    <>
      <div className="d-flex flex-column view-height bg-white justify-content-center align-items-center">
          <div className="container-sm" style={{ maxWidth: 770 }}>

                <h1 className="text-primary text-huge pb-5 text-center">
                  {t("endInfo")}
                </h1>
                <h4 className="text-secondary heading-2 mt-5 text-center">
                  {t("thankYouForChoosing")}
                </h4>
            </div>
          <img
          src={logo}
          width={230}
          alt="Recharge logo"
          className="recharge-logo"
          style={{  }}
        />
      </div>
      <Footer back={false} onNextPressed={() => dispatch(resetViews())} />
    </>
  );
}
