import { Modal, Button } from "react-bootstrap";
import forward from "../assets/recharge/footer-forward-r.png";
import cancelIcon from "../assets/recharge/footer-cancel-r.png";
import { EnvelopeCheck } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

type Props = {
  show?: boolean;
  handleClose(): void;
  handleConfirm(): void;
  email: string;
};

export const CheckEmailModal = ({ show, handleClose, handleConfirm, email }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton style={{position: "sticky", top: 0, backgroundColor: "white", opacity: 1, zIndex: 1055}}>
        </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <EnvelopeCheck size={200} className="svg-secondary"/>
        <h1 className="mt-4 text-center" style={{width: 500}}>{t("confirmEmail")}</h1>
        <h1 className="text-secondary mt-4"> {email}</h1>
      </Modal.Body>
      <Modal.Footer className="modal-footer justify-content-center">
      <Button className="bg-transparent btn-outline-none svg-primary mx-4" onClick={handleClose} style={{width: 100, height: 100}}>
            <img src={cancelIcon} alt="confirm" width="100" />
        </Button>
        <Button className="bg-transparent btn-outline-none svg-secondary mx-4" onClick={handleConfirm} style={{width: 100, height: 100}}>
            <img src={forward} alt="confirm" width="100" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};