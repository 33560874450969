import {createInstance} from 'i18next';
import { initReactI18next } from 'react-i18next';

const i18n = createInstance({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    fi: {
      translations: require('./locales/fi/translations.json')
    },
    se: {
        translations: require('./locales/se/translations.json')
    },
    no : {
      translations: require('./locales/no/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'fi', 'se', 'no'];

i18n.use(initReactI18next).init();

export default i18n;