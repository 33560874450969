import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Footer } from "../components/Footer";
import { navigate } from "../reducers/views";
import logo from "../assets/recharge/recharge-logo.svg";
import happy from "../assets/icons/happy.svg"
import meh from "../assets/icons/meh.svg"
import pain from "../assets/icons/pain.svg"
import smile from "../assets/icons/smile.svg"
import { useState } from "react";

export const Feedback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [feedback, setFeedback] = useState(0)

  const handleFeedback = async (value : number) => {
    setFeedback(value)
    await new Promise(r => setTimeout(r, 500)); //added this just that it looks cooler
    // send to backend 
    dispatch(navigate("End"))
  }
  return (
    <>
      <div className="d-flex flex-column view-height bg-white justify-content-center align-items-center">
        <h1 className="text-huge text-primary text-center mb-5">
            {t("feedback")}
        </h1>
        <div className="container-sm d-flex flex-row justify-content-center mt-5">
            <img 
                className={`mx-4 ${feedback === 1 ? "svg-secondary" : ""}`}
                src={pain}
                alt="Unhappy"
                style={{width: "20%"}}
                onClick={() => handleFeedback(1)}
            />
            <img 
                className={`mx-4 ${feedback === 2 ? "svg-secondary" : ""}`}                
                src={meh}
                alt="Neutral"
                style={{width: "20%"}}
                onClick={() => handleFeedback(2)}
            />
            <img 
                className={`mx-4 ${feedback === 3 ? "svg-secondary" : ""}`}
                src={smile}
                alt="Happy"
                style={{width: "20%"}}
                onClick={() => handleFeedback(3)}
            />
            <img 
                className={`mx-4 ${feedback === 4 ? "svg-secondary" : ""}`}
                src={happy}
                alt="Very happy"
                style={{width: "20%"}}
                onClick={() => handleFeedback(4)}
            />
        </div>
          <img
          src={logo}
          width={230}
          alt="Recharge logo"
          className="recharge-logo"
          style={{  }}
        />
      </div>
      <Footer />
    </>
  );
}

