import React from "react";
import { Footer } from "../components/Footer";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { navigate } from "../reducers/views";
import logo from "../assets/recharge/recharge-logo.svg";

export const Start = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  return (
    <>
      <div className=" bg-white view-height d-flex flex-column justify-content-center align-items-center">
          <div className="container-sm" style={{ maxWidth: 767 }}>
                <h1 className="text-huge pb-5">
                    {t('cableIsPlugged')}
                </h1>
                <h2 className="text-secondary mt-5 heading-2">
                    {t('cableIsConnected')}
                </h2>
          </div>
        <img
          src={logo}
          width={230}
          alt="Recharge logo"
          className="recharge-logo"
          style={{  }}
        />
        <Footer back onNextPressed={() =>dispatch(navigate("SelectConnectorID"))} />
      </div>
    </>
  );
}