import finnishFlag from '../assets/flags/fin-flag.png'
import swedishFlag from '../assets/flags/swe-flag.png'
import britishFlag from '../assets/flags/eng-flag.png'
import norwegianFlag from '../assets/flags/nor-flag.png'
import { useTranslation } from 'react-i18next';

export const ChooseLanguage = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (e: string) => {
        //setLanguage(e)
        i18n.changeLanguage(e)
    }

    const classes =  "btn-sm btn-primary ms-5"

    return (
        <div className="">
            <button  onClick={() => changeLanguage('en')}  className={classes} data-testid="en-flag" style={{padding: 1}}>
                <img src={britishFlag} height="100%" width="100%" alt="EN"/>
            </button>
            <button onClick={() => changeLanguage('fi')} className={classes} data-testid="fi-flag" style={{padding: 1}}>
                <img src={finnishFlag} height="100%" width="100%" alt="FI"/>
            </button>
            <button onClick={() => changeLanguage('se')} className={classes} style={{padding: 1}}>
                <img src={swedishFlag} height="100%" width="100%" alt="SE"/>
            </button>
            <button onClick={() => changeLanguage('no')} className={classes} style={{padding: 1}}>
                <img src={norwegianFlag} height="100%" width="100%" alt="NO"/>
            </button>
        </div>
    )
}