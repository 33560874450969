import React from "react";
import { Footer } from "../components/Footer";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { navigate } from "../reducers/views";

export const PaymentError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  return (
    <>
      <div className=" bg-white view-height d-flex flex-column justify-content-center align-items-center">
          <div className="container-sm" style={{ maxWidth: 767 }}>
                <h1 className="text-huge pb-5">
                    {t('somethingWrong')}
                </h1>
                <h2 className="text-secondary mt-5 heading-2">
                    {t('paymentFailed')}
                </h2>
          </div>
        <Footer back={false} onNextPressed={() =>dispatch(navigate("Idle"))} />
      </div>
    </>
  );
}