import { Cabinet } from "../models/cabinet";
import { Connector } from "../models/connector";
import { Recognition } from "../models/recognition";
import { Session } from "../models/session";

// Remove these from production
export let debugKioskToken = ""
export const setDebugKioskToken = (token: string) => (debugKioskToken = token)
// -------------------------------

export class TotemApiClient {
  deploymentEnv = process.env.REACT_APP_DEPLOYMENT_ENV || "test";
  baseUrl = process.env.NODE_ENV === "development" ? "http://localhost:8080" : `https://kiosk-api.${this.deploymentEnv}.farol.it`;
  kioskToken = process.env.REACT_APP_KIOSK_TOKEN || debugKioskToken ||  "recharge-pilot-norway";
  defaultHeaders = { "Content-Type": "application/json", "Accept": "application/json", "x-kiosk-token": this.kioskToken };

  constructor(baseUrl?: string) {
    if (baseUrl) {
      this.baseUrl = baseUrl;
    }
  }

  async getCabinets(): Promise<Cabinet[]> {
    try {
      const res = await this.get("v1/kiosks/self");
      return (await res.json()).cabinets;
    } catch (e) {
      return [];
    }
  }

  async getConnectors(): Promise<Connector[]> {
    return await this.get("v1/locations")
      .then(r => r.json())
      .catch(_ => []);
  }

  async startSession(payload: {
    evseUid: string;
    amountToReserve: number;
  }) : Promise<Session> {
    return this.post("v1/charging-sessions", JSON.stringify(payload)).then(r => r.json());
  }

  async getSession(id: string): Promise<Session> {
    return this.get(`v1/charging-sessions/${id}`).then(s => s.json());
  }

  async cancelSession(id: string): Promise<Session> {
    return this.post(`v1/charging-sessions/${id}/cancel`, null).then(s => s.json());
  }

  async stopSession(id: string): Promise<Session> {
    return this.post(`v1/charging-sessions/${id}/stop`, null).then(s => s.json());
  }

  async getEmail(sessionId: string): Promise<boolean> {
    return this.get(`v1/emails/${sessionId}`).then(r => r.status === 200);
  }

  async sendEmail({ sessionId, email }: { sessionId: string; email: string; }) {
    return this.post(`v1/emails/${sessionId}`, JSON.stringify({ email }));
  }

  async startRecognition() : Promise<Recognition> {
    return this.post("v1/recognitions", null).then(r => r.json());
  }

  async getRecognition(id: string): Promise<Recognition> {
    return this.get(`v1/recognitions/${id}`).then(r => r.json());
  }

  async cancelRecognition( id: string ): Promise<Recognition> {
    return this.get(`v1/recognitions/${id}/cancel`).then(r => r.json());
  }

  async simulateWorldLineReferenceCallback ({ sessionId, cardToken }: { sessionId: string; cardToken?: string; }) {
    const body = {
      approved: 1,
      txid: sessionId,
      reference: "reservation",
      cardinfo: {
        cardtoken: cardToken ?? "miao"
      },
      status: "final",
      ssai: 1966994,
      callbackID: 4452315,
      errormsg: "No purse in reader and time out expired",
      time: 1651934575.116418,
      terminal: "01493334",
      user: "EVTerminals",
      error: 1,
      cancelled: 1,
      password: "abc",
      incidentcode: "4100",
    };

    return this.post(`terminal/callback`, JSON.stringify(body));
  }

  async simulateWorldLineRecognitionCallback ({ recognitionId, cardToken }: { recognitionId: string; cardToken?: string; }) {
    const body = {
      approved: 1,
      txid: recognitionId,
      reference: recognitionId,
      cardinfo: {
        cardtoken: cardToken ?? "miao"
      },
      status: "cardrecognition",
      ssai: 1966994,
      callbackID: 4452315,
      errormsg: "",
      time: 1651934575.116418,
      terminal: "01493334",
      user: "EVTerminals",
      error: 0,
      cancelled: 0,
      password: "abc",
      incidentcode: "4100",
    };

    return this.post(`terminal/callback`, JSON.stringify(body));
  }

  // PRIVATE
  private get(path: string) : Promise<Response> {
    return fetch(`${this.baseUrl}/${path}`, { headers: this.defaultHeaders});
  }

  private post(path: string, body: any) : Promise<Response> {
    return fetch(`${this.baseUrl}/${path}`, { method: "POST", body: body, headers: this.defaultHeaders });
  }
}
