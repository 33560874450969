import { configureStore } from "@reduxjs/toolkit";
import viewsReducer from "./reducers/views";
import chargingSessionReducer from "./reducers/chargingSession";

const store = configureStore({
  reducer: {
    views: viewsReducer,
    chargingSession: chargingSessionReducer,
  }
})

type RootState = ReturnType<typeof store.getState>

export const activeViewSelector = (state: RootState) => state.views.active
export const connectorIdSelector = (state: RootState) => state.chargingSession.connector.connectorId
export const connectorsSelector = (state: RootState) => state.chargingSession.selectedConnectors
export const evseUidSelector = (state: RootState) => state.chargingSession.connector.evseUid

export const chargingSessionPaymentSelector = (state: RootState) => state.chargingSession.payment
export const chargingSessionIdSelector = (state: RootState) => state.chargingSession.id

export default store