import { Modal, Button } from "react-bootstrap";

type Props = {
  show?: boolean;
  handleClose(): void;
};

export const PrivacyPolicyModal = ({ show, handleClose }: Props) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton style={{position: "sticky", top: 0, backgroundColor: "white", opacity: 1, zIndex: 1055}}>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5> Recharge AS, Recharge Sweden AB, Recharge Finland OY</h5>
        <p>
          {" "}
          In our updated Privacy Policy, you can read more on how we treat your
          personal data. This, together with any other documents referred to
          within, sets out the basis on which we will process any personal data
          that we collect from you, or that you provide to us. Please read the
          following carefully to understand our views and practices regarding
          your personal data and how we will treat it.{" "}
        </p>
        <h4>The data controller</h4>
        <p>
          For the purpose of the EU General Data Protection Regulation 2016
          (&laquo;GDPR&raquo;), the data controller is Recharge AS, Rådmann
          Siras Vei 1, 1712 Grålum, Norway (herein after
          &laquo;Recharge&raquo;).
        </p>
        <h4>How does Recharge process your personal data?</h4>
        <h6>What kind of data does Recharge collect?</h6>
        <p>
          Recharge collects data that is necessary for the relationship you have
          with us and the purposes for which the data are used. Recharge
          collects the following personal data categories.
        </p>
        <ul>
          <li>Permission data &#8211; such as marketing permissions</li>
          <li>
            Security data &#8211; such as passwords, security incident
            information
          </li>
          <li>
            Financial data &#8211; such as payment card details • Identifying
            data &#8211; such as name
          </li>
          <li>
            Behavioral data &#8211; such as customer profiling and other
            information derived from your use of our services
          </li>
          <li>
            Communications &#8211; such as your answers to surveys, and other
            feedback and communications you send us
          </li>
          <li>
            Data on your use of our services &#8211; such as energy /
            electricity consumption data
          </li>
          <li>Computer device data &#8211; such as IP address, cookie data</li>
          <li>Contact data &#8211; such as email address, telephone number</li>
          <li>Contract data &#8211; such as payment terms</li>
        </ul>
        <h4>What sources are the personal data obtained from?</h4>
        <p>
          The processed personal data include data that are received from you at
          the time of the order, at the time of joining our service, or during
          the customer relationship. We also receive observed data from your use
          of our devices and services. Recharge may also process derived data
          which have been derived from or concluded based on the received data.
          In addition, we use Google Analytics to track and analyze user online
          behavior.
        </p>
        <h4>What are the purposes for processing personal data?</h4>
        <p>
          We process personal data only for predefined purposes. The purposes
          for which we process personal data are:
        </p>
        <ul>
          <li>
            Customer relationship management (e.g sending of contract related
            notifications, product or service related notifications or updates,
            customer guidance)
          </li>
          <li>Contract and product management</li>
          <li>
            Providing, managing and maintaining the service (e.g. end-user
            license management, investigating and fixing technical issues)
          </li>
          <li>Identification of data subjects</li>
          <li>Suspension of provided services</li>
          <li>Receipt and transaction information storing</li>
          <li>Contract archiving</li>
          <li>
            Delivering goods and services, including returns&#8217; management
          </li>
          <li>
            Complaint and Dispute management, including compensation demands
          </li>
          <li>
            Transferring personal data (to include transfers and disclosures)
          </li>
          <li>
            Profiling, segmentation, and direct marketing (for existing and
            prospective customers)
          </li>
          <li>Sales and Marketing (including e.g. organizing seminars)</li>
          <li>Billing and debt collection</li>
          <li>Refunds of payments to customers</li>
          <li>Internal reporting</li>
          <li>Establishment, exercise or defense of legal claims</li>
          <li>Partner reporting</li>
          <li>
            Product and Service development, including feedback and surveys
          </li>
          <li>Customer satisfaction surveys</li>
          <li>Vendor communications</li>
          <li>Fraud protection</li>
        </ul>
        <h4>On what legal basis do we process your personal data?</h4>
        <p>
          In order for us to be able to process your personal data, we rely on
          different legal bases, including:
        </p>
        <ul>
          <li>
            Your consent. If we rely on your consent as a legal basis for
            processing of your data, you may withdraw your consent at any time •
            The necessity to establish a contractual relationship with you and
            to perform our contractual obligations
          </li>
          <li>
            The necessity for us to comply with a legal obligation (for
            instance, we are required by law to store certain data for a
            specific period of time) and to establish, exercise, or defend
            Recharge against legal claims
          </li>
          <li>
            The necessity to pursue our legitimate interests, including:
            <ul>
              <li>Internal reporting</li>
              <li>Partner reporting</li>
              <li>
                Product and service development including feedback and surveys
              </li>
              <li>
                Transferring personal data (tinclude transfers and disclosures)
              </li>
              <li>Compensation demands</li>
              <li>Customer profiling or segmentation</li>
              <li>Direct marketing for existing and prospective customers</li>
              <li>Debt collection</li>
            </ul>
          </li>
          <li>The necessity to protect the vital interests of any person</li>
        </ul>
        <h4>Automated decision-making</h4>
        <p>
          We may make decisions about you through automated decision making.
          Such include e.g. automated checking of the validity of your payment
          card; automated pattern detection in the use of your payment card to
          detect, investigate and prevent potential frauds; and automated
          tracking of your potential debt to Recharge from previous unpaid use
          of our services.
        </p>
        <p>
          Our automated decision-making procedures may affect your ability to
          use our services. We may need to do this either to perform our legal
          obligations or because it is necessary for entering into, or the
          performance of, a contract between you and us. We also carry out
          profiling to provide our customers with better targeted sales and
          marketing communication.
        </p>
        <p>
          If we have made a decision about you solely on the basis of an
          automated process (e.g. through automatic profiling) and that affects
          your ability to use the services or has another significant effect on
          you, you can ask to not be subject to such a decision unless we can
          demonstrate to you that such decision is necessary for entering into,
          or the performance of, a contract between you and us.
        </p>
        <h4>How long do we store the personal data?</h4>
        <p>
          Recharge seeks to limit the period for which the personal data are
          stored to a minimum. Thus, Recharge processes your personal data only
          to the extent and as long as is necessary to meet the purposes of the
          data processing.
        </p>
        <p>
          The specific retention periods may be different depending on the
          categories of data. As a general rule, your personal data are stored
          for a period of three (3) years from the end of the last charging
          session/end of customer relationship. Whereas e.g. information about
          transactions will be kept for at least five (5) years on legal basis.
          Also, when using SMS as a payment method, your mobile subscription
          number, subject to certain conditions (e.g. insufficient prepaid
          balance or blocked Premium SMS), might be added onto a so-called black
          list, where we are required to retain your number for at least half a
          year.
        </p>
        <p>
          Recharge sets out and regularly re-evaluates data type specific
          retention periods for the personal data it holds. Once personal data
          is no longer necessary, Recharge will delete it or anonymize it as
          soon as possible.
        </p>
        <h4>Who processes your personal data?</h4>
        <p>
          Principally, we do not sell, trade or license any personal data to
          third parties. Companies belonging to the Recharge group of companies
          may process personal data in accordance with existing privacy laws.
          Personal data may be disclosed to our authorized employees or
          affiliates to the extent necessary for the purpose of processing. The
          data will never be available to all employees but to a limited number
          of authorized persons.
        </p>
        <p>
          We also use third parties as our data processors to help us develop,
          deliver and maintain our products and services, and fulfil our other
          purposes as defined in this Privacy Policy. When a third party
          processes personal data on our behalf, we always ensure via
          contractual arrangements that the processing of personal data is
          always conducted safely and in accordance with privacy laws and data
          processing best practices.
        </p>
        <p>
          <strong>
            List of categories of the third parties processing data (=data
            processors):{" "}
          </strong>
        </p>
        <ul>
          <li>
            Service providers, such as printing services, customer service,
            installation partners
          </li>
          <li>Sales and marketing partners</li>
          <li>Payment Service Providers</li>
          <li>Cloud Service Providers</li>
          <li>Charger Manufacturers</li>
          <li>Roaming platform providers</li>
          <li>
            IT service providers and Consultancies (e.g. developers, designers,
            and testers)
          </li>
          <li>
            Service and maintenance partners (e.g. HW repair and maintenance)
          </li>
          <li>Charger Cloud systems providers</li>
          <li>Invoicing and debt collection service and system providers</li>
          <li>Data hosting system providers</li>
          <li>Warehouse service and management system providers</li>
          <li>
            Software and tool providers (e.g. for software development, business
            analytics, sales, marketing, work order management, customer
            relationship management, online conferencing and communication)
          </li>
          <li>
            Operational companies such as the post office or delivery couriers
          </li>
          <li>
            Research companies (for e.g. conducting customer satisfaction or
            product and service development surveys)
          </li>
          <li>Telecommunications system providers</li>
        </ul>
        <p>
          In addition, personal data may be disclosed to authorities when we are
          required to do so by law, based on demands made by competent
          authorities in accordance with existing privacy laws.
        </p>
        <h4>Does Recharge transfer personal data to third countries?</h4>
        <p>
          Principally, Recharge does not transfer personal data outside the
          European Union or the European Economic Area (EEA). However, if
          personal data is transferred outside the EU or the EEA, Recharge uses
          appropriate safeguards in accordance with existing privacy
          legislation, such as the standard contractual clauses provided by the
          European Commission.
        </p>
        <p>
          When using the Recharge Digital Solution, your payment card
          information is stored and processed in the United States via Stripe,
          Inc. Stripe is subscribed to the EU-U.S. Privacy Shield Framework and
          to the Swiss-U.S. Privacy Shield Framework, which is a policy that
          describes privacy practices for handling personal data received from
          the EEA or Switzerland in the United States. You can find more
          information on Stripe&#8217;s subscription and on the principles of
          the Privacy Shield at the below link.
        </p>
        <p>
          <a
            href="https://stripe.com/privacy-shield-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://stripe.com/privacy-shield-policy
          </a>
        </p>
        <h4>How does Recharge protect the personal data?</h4>
        <p>
          Recharge fulfils the necessary technical and organizational measures,
          which ensure and demonstrate that privacy laws are being followed in
          the processing of personal data.
        </p>
        <p>
          These measures include the use of Identity and Access Management
          systems to ensure that only authorized persons have access to personal
          data, the use of firewalls, IP filtering, multifactor authentication,
          pseudonymization of data, encryption of data, detailed instructions
          and training for personnel on protection of personal data, and careful
          consideration when selecting our service providers that are involved
          in the processing of personal data on our behalf.
        </p>
        <h4>
          How do we handle personal data from IP addresses, cookies and similar
          technologies
        </h4>
        <h6>Cookies used on websites</h6>
        <p>
          Recharge may collect data on the user’s devices via cookies and other
          applicable technologies.
        </p>
        <p>
          Cookies are data, which the server of the service provider sends to
          the user’s web browser asking the browser to store the data on the
          user’s device and which the server can later request to receive back.
          In practice, cookies contain a small amount of data, typically a short
          piece of text. With cookies we can identify and count the browsers
          visiting our websites, user preferences and other patterns of user
          behavior.
        </p>
        <p>
          The user can block the use of cookies by changing the settings in his
          or her web browser. Blocking the use of cookies may affect the
          functionality of our services.
        </p>
        <h6>Third party access to data collected on Recharge’s websites</h6>
        <p>
          Third parties are entities outside of Recharge, such as marketers,
          advertisement networks and providers of measurement and tracking
          services. Third parties may process the user’s personal data for
          example when the user visits our websites or uses the services we
          provide. A third party may then use the user’s personal data, for
          example, to provide the user with targeted advertisements or to
          compile statistics on the user counts of different websites.
        </p>
        <p>
          We ensure via contractual arrangements that the third parties comply
          with existing privacy laws.
        </p>
        <h6>Location data</h6>
        <p>
          The location data of the user’s device may be used to provide services
          based on the location of the device, if the user has given a clear
          consent to the processing of location data. The user has the right to
          withdraw the consent at any time by changing appropriate settings on
          their device.
        </p>
        <h4>What are your rights when it comes to your personal data?</h4>
        <h6>The rights of the user</h6>
        <p>
          You have the right to demand Recharge as a data controller to allow
          access to your personal data and the right to ask for the correction
          or deletion of said data. Additionally, if no legal limitations apply,
          you have the right to restrict the processing, to object to the
          processing and to request for your personal data to be transferred to
          another system.
        </p>
        <p>
          When the processing of personal data has been based on your consent,
          you have the right to withdraw your consent at any time.
        </p>
        <p>
          Finally, you have the right to lodge a complaint with the supervisory
          authority concerning the processing of personal data by Recharge.
        </p>
        <h6>Blocking of direct marketing</h6>
        <p>
          You always have the right to forbid the processing of your personal
          data for direct marketing purposes. The prohibition of direct
          marketing can be expressed by sending an email to{" "}
          <a href="mailto:privacy@rechargeinfra.com">
            privacy@rechargeinfra.com
          </a>
          .
        </p>
        <h6>Automated decision-making</h6>
        <p>
          If we have made a decision about you solely on the basis of an
          automated process (e.g. through automatic profiling) and that affects
          your ability to use the services or has another significant effect on
          you, you can ask to not be subject to such a decision unless we can
          demonstrate to you that such decision is necessary for entering into,
          or the performance of, a contract between you and us.
        </p>
        <h4>Changes to our Privacy Policy</h4>
        <p>
          We reserve the right to amend this Privacy Policy. Any changes to our
          Privacy Policy will be communicated to our customers through
          www.rechargeinfra.com.
        </p>
        <p>
          Amendments may be necessary due to the development of our services or,
          for example, changes in the relevant laws.
        </p>
        <h4>Contact</h4>
        <p>
          Questions, comments and requests regarding this privacy policy are
          welcome and should be addressed as instructed below.
        </p>
        <p>
          By post: Recharge AS Att: Privacy, Rådmann Siras Vei 1, N-1712 Grålum,
          NORWAY
        </p>
        <p>
          By email:{" "}
          <a href="mailto:privacy@rechargeinfra.com">
            privacy@rechargeinfra.com
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button variant="secondary" onClick={handleClose} style={{width: 150, height: 80, fontSize: 30}}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
