import React, { Dispatch, SetStateAction, useState } from "react";
import { TotemApiClient } from "../clients/TotemApiClient";
import { useDispatch, useSelector } from "react-redux";
import { chargingSessionIdSelector } from "../store";
import { navigate } from "../reducers/views";
import { useTranslation } from "react-i18next";
import { Footer } from "../components/Footer";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { TermsAndConditionsModal } from "../components/TermsAndConditionsModal";
import { PrivacyPolicyModal } from "../components/PrivacyPolicyModal";
import { CheckEmailModal } from "../components/CheckEmailModal";

export const TermsAndConditions = () => {
  const [isInputFocused, setInputFocused] = useState(false);

  const [isEmail, setEmail] = useState(""); //email address is input

  const [showCheckEmail, setShowCheckEmail] = useState(false)
  const handleCloseCheckEmail = () => setShowCheckEmail(false)
  const handleConfirmEmail = () => {
    handleSubmit()
    setShowCheckEmail(false)
  }

  const [showTC, setShowTC] = useState(false);
  const [showPP, setShowPP] = useState(false);

  const handleShowTC = () => setShowTC(true);
  const handleShowPP = () => setShowPP(true);

  const handleClose = () => (showTC ? setShowTC(false) : setShowPP(false));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const client = new TotemApiClient();
  const [consents, setConsents] = useState<Record<Consent, boolean>>({
    ml: false,
    pp: false,
    tc: false,
  });
  const sessionId = useSelector(chargingSessionIdSelector);

  const handleSubmit = async () => {
    if (isEmail && sessionId) {
      client
        .sendEmail({ sessionId, email: isEmail })
        .then(() => dispatch(navigate("Feedback")))
        .catch((e) => console.log("something bad happened", e));
    }
  };
  const handleToggleConsent = (consent: Consent) => () => {
    setConsents({ ...consents, [consent]: !consents[consent] });
  };

  //Keyboard functions
  const onFocus = () => {
    setInputFocused(true);
  };

  const onChangeInput = ({ target }: any) => {
    setEmail(target.value);
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center bg-white"
        style={{
          height: isInputFocused
            ? "calc(100vh - 700px)"
            : "calc(100vh - 300px)",
        }}
      >
        <div
          className="input-group mb-3 input-group-lg border border-secondary border-2"
          style={{ width: 700, height: 150 }}
        >
          <span
            className="input-group-text fs-1 text-secondary"
            id="email-addon"
          >
            @
          </span>
          <input
            type="text"
            className="form-control fs-1"
            placeholder="your@email.com"
            aria-label="email"
            aria-describedby="email-addon"
            onChange={onChangeInput}
            onFocus={onFocus}
            value={isEmail}
          />
        </div>
        <div className="my-5">
          <input
            type="checkbox"
            className="form-check-input tcCheckbox"
            id="tc-check"
            onChange={handleToggleConsent("tc")}
            checked={consents.tc}
          />
          <label
            className={`form-check-label ${
              consents.tc ? "tc-chosen" : "tc-text"
            } `}
          >
            {t("accept")}
            <p
              onClick={handleShowTC}
              className={`consents.pp ? "tc-chosen" : "tc-text"`}
              style={{ textDecoration: "underline" }}
            >
              {t("acceptTerms")}
            </p>
          </label>
          <TermsAndConditionsModal show={showTC} handleClose={handleClose} />
        </div>
        <div className="mt-5">
          <input
            type="checkbox"
            className="form-check-input tcCheckbox"
            id="pp-check"
            onChange={handleToggleConsent("pp")}
            checked={consents.pp}
          />
          <label
            className={`form-check-label ${
              consents.pp ? "tc-chosen" : "tc-text"
            } `}
          >
            {t("accept")}
            <p
              onClick={handleShowPP}
              className={`consents.pp ? "tc-chosen" : "tc-text"`}
              style={{ textDecoration: "underline" }}
            >
              {t("acceptPrivacy")}
            </p>
          </label>
          <PrivacyPolicyModal show={showPP} handleClose={handleClose} />
        </div>
      </div>
      <CheckEmailModal show={showCheckEmail} handleClose={handleCloseCheckEmail} handleConfirm={handleConfirmEmail} email={isEmail}/>

      {isInputFocused && (
        <CustomKeyboard setInputFocused={setInputFocused} setEmail={setEmail} />
      )}
      <Footer
        onBackPressed={() => dispatch(navigate("Receipt"))}
        onNextPressed={consents.pp && consents.tc ? () => setShowCheckEmail(true) : undefined}
      />
    </>
  );
};

type Consent = "tc" | "pp" | "ml";

type KeyboardProps = {
  setInputFocused: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
};

const CustomKeyboard = (props: KeyboardProps) => {
  const display = {
    "{alt}": ".?123",
    "{shift}": "⇧",
    "{shiftactivated}": "⇧",
    "{enter}": "⏎",
    "{bksp}": "⌫",
    "{altright}": ".?123",
    "{downkeyboard}": "🞃",
    "{space}": " ",
    "{default}": "ABC",
    "{back}": "⇦",
  };

  const [layoutName, setLayoutName] = useState("default");

  const onChange = (input: string) => {
    props.setEmail(input);
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{shiftactivated}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    if (button === "{alt}") {
      setLayoutName(layoutName === "default" || "shift" ? "alt" : "default");
    }
    if (button === "{default}") {
      setLayoutName("default");
    }
    if (button === "{enter}") {
      props.setInputFocused(false);
    }
  };

  return (
    <div className="keyboard">
      <Keyboard
        layoutName={layoutName}
        theme="hg-theme-default hg-theme-ios hg-layout-default"
        display={display}
        onChange={onChange}
        onKeyPress={onKeyPress}
        layout={{
          default: [
            "1 2 3 4 5 6 7 8 9 0",
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{shift} z x c v b n m {bksp}",
            "{alt} @ , {space} . {enter}",
          ],
          shift: [
            "1 2 3 4 5 6 7 8 9 0",
            "Q W E R T Y U I O P",
            "A S D F G H J K L",
            "{shiftactivated} Z X C V B N M {bksp}",
            "{alt} @ , {space} . {enter}",
          ],
          alt: [
            "1 2 3 4 5 6 7 8 9 0",
            `# $ & * ' "`,
            "% - + = / ; :",
            "( ) ! ? {bksp}",
            "{default} @ , {space} . {enter}",
          ],
        }}
      />
    </div>
  );
};
