
import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { navigate } from "../reducers/views";
import stopIcon from "../assets/stop-icon.png";
import { useTranslation } from "react-i18next";

export const StopButton = () : ReactElement => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
      <div onClick={() => dispatch(navigate("ReadyToStop"))} className="bg-primary row no-gutters round" style={{ minHeight: 200, minWidth: 750, width: "100%" }}>
        <div className="col col-9 text-start">
          <h1 className="text-home-huge text-secondary pt-4 mb-3 ms-4 text-uppercase">{t("stop")}</h1>
          <h4 className="text-white ms-4">{t("stopDesc")}</h4>
        </div>
        <div className="col col-3 d-flex justify-content-center align-items-center" style={{ minHeight: 'inherit' }}>
          <img src={stopIcon} alt="Connector" width={110} className="white-icon" />
        </div>
      </div>
    );
  }