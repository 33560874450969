import { Connector } from "./connector";
import alpitronic from "../assets/alpitronic-cabinet.png"
import efacec from "../assets/efacec-cabinet.png"
import kempower from "../assets/kempower-cabinet.png"
import easee from "../assets/farol-hq-easee.png"

export enum CabinetType {
  ALPITRONIC = "Alpitronic_HYC_150_300_1_6_J",
  EFACEC = "Efacec_QC45_SD_1_6_J",
  KEMPOWER = "Kempower",
  // For development
  EASEE = "Easee",
}

export const getImageForCabinet = (type: CabinetType) => {
  switch (type) {
    case CabinetType.ALPITRONIC:
      return alpitronic;
    case CabinetType.EFACEC:
      return efacec;
    case CabinetType.KEMPOWER:
      return kempower;
    case CabinetType.EASEE:
      return easee;
    default:
      throw new Error(`Unknown CabinetType: ${type}`);
  }
}

export interface Cabinet {
  id: string;
  position: number;
  cabinetType: CabinetType;
  connectors: Connector[];
} 