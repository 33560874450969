import type2 from "../assets/type2-icon.png";
import chademo from "../assets/chademo-icon.png";
import ccs from "../assets/CCS-icon.png";

export enum ConnectorType {
  CHADEMO = 'CHADEMO',
  DOMESTIC_A = 'DOMESTIC_A',
  DOMESTIC_B = 'DOMESTIC_B',
  DOMESTIC_C = 'DOMESTIC_C',
  DOMESTIC_D = 'DOMESTIC_D',
  DOMESTIC_E = 'DOMESTIC_E',
  DOMESTIC_F = 'DOMESTIC_F',
  DOMESTIC_G = 'DOMESTIC_G',
  DOMESTIC_H = 'DOMESTIC_H',
  DOMESTIC_I = 'DOMESTIC_I',
  DOMESTIC_J = 'DOMESTIC_J',
  DOMESTIC_K = 'DOMESTIC_K',
  DOMESTIC_L = 'DOMESTIC_L',
  IEC_60309_2_single_16 = 'IEC_60309_2_single_16',
  IEC_60309_2_three_16 = 'IEC_60309_2_three_16',
  IEC_60309_2_three_32 = 'IEC_60309_2_three_32',
  IEC_60309_2_three_64 = 'IEC_60309_2_three_64',
  IEC_62196_T1 = 'IEC_62196_T1',
  IEC_62196_T1_COMBO = 'IEC_62196_T1_COMBO',
  IEC_62196_T2 = 'IEC_62196_T2',
  IEC_62196_T2_COMBO = 'IEC_62196_T2_COMBO',
  IEC_62196_T3A = 'IEC_62196_T3A',
  IEC_62196_T3C = 'IEC_62196_T3C',
  PANTOGRAPH_BOTTOM_UP = 'PANTOGRAPH_BOTTOM_UP',
  PANTOGRAPH_TOP_DOWN = 'PANTOGRAPH_TOP_DOWN',
  TESLA_R = 'TESLA_R',
  TESLA_S = 'TESLA_S',
}

const connectorTypeNameMap = new Map<ConnectorType, string>([
  [ConnectorType.CHADEMO, 'CHAdeMO'],
  [ConnectorType.IEC_62196_T2_COMBO, 'CCS'],
  [ConnectorType.IEC_62196_T2, 'Type 2']
]);

const connectorTypePriceMap = new Map<ConnectorType, string>([
  [ConnectorType.CHADEMO, '50 kW - 0,50 EUR/min'],
  [ConnectorType.IEC_62196_T2_COMBO, '150 kW - 0,50 EUR/min'],
  [ConnectorType.IEC_62196_T2, '22 kW - 0,20 EUR/min'],

]);

const connectorTypeImageMap = new Map<ConnectorType, string>([
  [ConnectorType.CHADEMO, chademo],
  [ConnectorType.IEC_62196_T2_COMBO, ccs],
  [ConnectorType.IEC_62196_T2, type2],
]);

export enum ConnectorStatus {
  AVAILABLE = 'AVAILABLE',
  BLOCKED = 'BLOCKED',
  CHARGING = 'CHARGING',
  INOPERATIVE = 'INOPERATIVE',
  OUTOFORDER = 'OUTOFORDER',
  PLANNED = 'PLANNED',
  REMOVED = 'REMOVED',
  RESERVED = 'RESERVED',
  UNKNOWN = 'UNKNOWN',
}

export interface Connector {
  connectorType?: ConnectorType | undefined;
  connectorId?: string;
  locationId?: string;
  evseUid?: string;
  status?: ConnectorStatus;
}

export const connectorTypeName = (connectorType: ConnectorType): string => connectorTypeNameMap.get(connectorType as ConnectorType) ?? 'Unknown'
export const connectorTypePrice = (connectorType: ConnectorType): string => connectorTypePriceMap.get(connectorType) ?? 'Unknown'
export const connectorTypeImage = (connectorType: ConnectorType): string | undefined => connectorTypeImageMap.get(connectorType)

export type ConnectorGroups = Map<ConnectorType | undefined, Connector[]>;
