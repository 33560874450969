import { Session } from "./session";

export interface Recognition {
  id: string;
  status: RecognitionStatus;
  session: Session | undefined;
}

export enum RecognitionStatus {
  STARTED = 'STARTED',
  CANCELLED = 'CANCELLED',
  FAILURE = 'FAILURE',
  CONCLUDED = 'CONCLUDED',
}